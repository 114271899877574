// Theme
export const THEME_SET = "THEME_SET"
export const THEMES = {
  DEFAULT: "DEFAULT",
  DARK: "DARK",
  LIGHT: "LIGHT",
  BLUE: "BLUE",
  GREEN: "GREEN",
  INDIGO: "INDIGO",
}

// Auth
export const AUTH_SIGN_IN_REQUEST = "AUTH_SIGN_IN_REQUEST"
export const AUTH_SIGN_IN_SUCCESS = "AUTH_SIGN_IN_SUCCESS"
export const AUTH_SIGN_IN_FAILURE = "AUTH_SIGN_IN_FAILURE"
export const AUTH_SIGN_OUT = "AUTH_SIGN_OUT"
export const AUTH_SIGN_UP_REQUEST = "AUTH_SIGN_UP_REQUEST"
export const AUTH_SIGN_UP_SUCCESS = "AUTH_SIGN_UP_SUCCESS"
export const AUTH_SIGN_UP_FAILURE = "AUTH_SIGN_UP_FAILURE"
export const AUTH_RESET_PASSWORD_REQUEST = "AUTH_RESET_PASSWORD_REQUEST"
export const AUTH_RESET_PASSWORD_SUCCESS = "AUTH_RESET_PASSWORD_SUCCESS"
export const AUTH_RESET_PASSWORD_FAILURE = "AUTH_RESET_PASSWORD_FAILURE"

// Apis
export const API_URL = process.env.REACT_APP_API_URL
export const ACCESS_TOKEN_REQ_OPTIONS = {
  audience: "operator-api.tesseractinvestment.com",
  scope: "tioperator:all",
}
