import * as React from "react"
import styled, { withTheme } from "styled-components/macro"
import { darken } from "polished"
import { Search as SearchIcon } from "react-feather"

import {
  Grid,
  Hidden,
  InputBase,
  AppBar as MuiAppBar,
  IconButton as MuiIconButton,
  Toolbar,
} from "@material-ui/core"

import { Menu as MenuIcon } from "@material-ui/icons"

import NotificationsDropdown from "./NotificationsDropdown"
import MessagesDropdown from "./MessagesDropdown"
import LanguagesDropdown from "./LanguagesDropdown"
import UserDropdown from "./UserDropdown"

const AppBar = styled(MuiAppBar)`
  background-color: rgba(0, 0, 0, 0);
  color: ${(props) => props.theme.header.color};
`

const IconButton = styled(MuiIconButton)`
  svg {
    width: 22px;
    height: 22px;
  }
`

const Search = styled.div`
  border-radius: 2px;
  background-color: ${(props) => props.theme.header.background};
  display: none;
  position: relative;
  width: 100%;

  &:hover {
    background-color: ${(props) => darken(0.05, props.theme.header.background)};
  }

  ${(props) => props.theme.breakpoints.up("md")} {
    display: block;
  }
`

const SearchIconWrapper = styled.div`
  width: 50px;
  height: 100%;
  position: absolute;
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 22px;
    height: 22px;
  }
`

const Input = styled(InputBase)`
  color: inherit;
  width: 100%;

  > input {
    color: ${(props) => props.theme.header.search.color};
    padding-top: ${(props) => props.theme.spacing(2.5)}px;
    padding-right: ${(props) => props.theme.spacing(2.5)}px;
    padding-bottom: ${(props) => props.theme.spacing(2.5)}px;
    padding-left: ${(props) => props.theme.spacing(12)}px;
    width: 160px;
  }
`

type AppBarProps = {
  theme: {}
  onDrawerToggle: React.MouseEventHandler<HTMLElement>
}

const AppBarComponent: React.FC<AppBarProps> = ({ onDrawerToggle }) => (
  <React.Fragment>
    <AppBar position="absolute" elevation={0}>
      <Toolbar>
        <Grid container alignItems="center">
          <Hidden mdUp>
            <Grid item>
              <IconButton
                color="inherit"
                aria-label="Open drawer"
                onClick={onDrawerToggle}
              >
                <MenuIcon />
              </IconButton>
            </Grid>
          </Hidden>
        </Grid>
      </Toolbar>
    </AppBar>
  </React.Fragment>
)

export default withTheme(AppBarComponent)
