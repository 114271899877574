import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"

import React from "react"
import ReactDOM from "react-dom"
import App from "./App"

import { Provider } from "react-redux"
import store from "./redux/store/index"
import { Auth0Provider } from "@auth0/auth0-react"

ReactDOM.render(
  <Provider store={store}>
    <Auth0Provider
      store={store}
      domain="auth.tesseractinvestment.com"
      clientId="oCtaHfAmAC47Ik6EXJ4PKIIHeH7PkRap"
      redirectUri={window.location.origin}
      audience="operator-api.tesseractinvestment.com"
      scope="tioperator:all"
      issuer="https://auth.tesseractinvestment.com/"
    >
      <App />
    </Auth0Provider>
    ,
  </Provider>,
  document.getElementById("root")
)
