import React from "react"
import async from "../components/Async"
import { Sliders } from "react-feather"

// Accounting components
const DeFi = async(() => import("../pages/accounting/DeFi"))
const Other = async(() => import("../pages/accounting/Other"))
const OperationOther = async(() => import("../pages/accounting/OperationOther"))
const OperationDeFi = async(() => import("../pages/accounting/OperationDeFi"))

const accountingRoutes = {
  id: "Accounting",
  path: "/",
  icon: <Sliders />,
  containsHome: true,
  children: [
    {
      path: "/",
      name: "DeFi",
      component: DeFi,
    },
    {
      path: "/accounting/operation-defi",
      name: "DeFi - Operation",
      component: OperationDeFi,
    },
    {
      path: "/accounting/other",
      name: "Other",
      component: Other,
    },
    {
      path: "/accounting/operation-other",
      name: "Other - Operation",
      component: OperationOther,
    },
  ],
  component: null,
}

export const dashboardLayoutRoutes = [accountingRoutes]

// Routes visible in the sidebar
export const sidebarRoutes = [accountingRoutes]
