import React, { useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { withAuthenticationRequired } from "@auth0/auth0-react"
import { useAuth0 } from "@auth0/auth0-react"

import { Helmet } from "react-helmet"

import DateFnsUtils from "@date-io/date-fns"
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles"
import { MuiPickersUtilsProvider } from "@material-ui/pickers"
import { StylesProvider } from "@material-ui/styles"
import { ThemeProvider } from "styled-components/macro"

import createTheme from "./theme"
import Routes from "./routes/Routes"
import { AppStateType } from "./redux/reducers"

function App() {
  const theme = useSelector((state: AppStateType) => state.themeReducer)
  const { getAccessTokenSilently } = useAuth0()
  const [authInitiated, setAuthInitiated] = useState<boolean>(false)

  useEffect(() => {
    getAccessTokenSilently({
      audience: "operator-api.tesseractinvestment.com",
      scope: "tioperator:all",
    }).then((accessToken) => {
      localStorage.setItem("token", accessToken)
      setAuthInitiated(true)
    })
  }, [])

  if (!authInitiated) return null

  return (
    <React.Fragment>
      <Helmet
        titleTemplate="%s | Tesseract Operator"
        defaultTitle="Tesseract Operator"
      />
      <StylesProvider injectFirst>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <MuiThemeProvider theme={createTheme(theme.currentTheme)}>
            <ThemeProvider theme={createTheme(theme.currentTheme)}>
              <Routes />
            </ThemeProvider>
          </MuiThemeProvider>
        </MuiPickersUtilsProvider>
      </StylesProvider>
    </React.Fragment>
  )
}

export default withAuthenticationRequired(App)
